<template>
  <canvas ref="canvas.webgl" class="webgl"></canvas>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { fogTexture, fogMesh, fogAnimationUpdate } from "./fog";
import { auroraMesh, auroraAnimationUpdate } from "./aurora";
import gsap from "gsap";
import { mapState } from "vuex";
import { whileStatement, WHILE_TYPES } from "@babel/types";
THREE.Cache.enabled = true;
export default {
  name: "ThreeDTourTest",
  data: () => ({
    loaderComplete: Array(5).fill(false),
    lastClickedName: "",
  }),
  mounted() {
    this.handleInit();
  },
  // computed: {
  //   ...mapState(['loading'])
  // },
  watch: {
    loaderComplete: {
      handler(v) {
        if (v.every(Boolean)) {
          this.$store.commit("setLoading", false);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleInit() {
      const vm = this;
      const canvas = this.$refs["canvas.webgl"];

      const renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: false,
        powerPreference: "high-performance",
      });
      renderer.setPixelRatio(1);

      const scene = new THREE.Scene();

      /**
       * Sky background
       */
      const materialArray = [];
      const textureLoader = new THREE.TextureLoader();
      const texture_ft = textureLoader.load("models/背景/kenon_star_ft.jpg");
      const texture_bk = textureLoader.load("models/背景/kenon_star_bk.jpg");
      const texture_up = textureLoader.load("models/背景/kenon_star_up.jpg");
      const texture_dn = textureLoader.load("models/背景/kenon_star_dn.jpg");
      const texture_rt = textureLoader.load("models/背景/kenon_star_rt.jpg");
      const texture_lt = textureLoader.load("models/背景/kenon_star_lf.jpg");

      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_ft,
          side: THREE.BackSide,
        })
      );
      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_bk,
          side: THREE.BackSide,
        })
      );
      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_up,
          side: THREE.BackSide,
        })
      );
      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_dn,
          side: THREE.BackSide,
        })
      );
      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_rt,
          side: THREE.BackSide,
        })
      );
      materialArray.push(
        new THREE.MeshBasicMaterial({
          map: texture_lt,
          side: THREE.BackSide,
        })
      );

      const skyGeometry = new THREE.BoxGeometry(800, 800, 800);
      const skyBox = new THREE.Mesh(skyGeometry, materialArray);

      scene.add(skyBox);

      /**
       * Loader
       */
      const gltfLoader = new GLTFLoader();

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("/draco/");
      gltfLoader.setDRACOLoader(dracoLoader);

      const SCALE_RATIO = 0.05;

      gltfLoader.load("./models/線上展基地模_地基new.gltf", (gltf) => {
        const model = gltf.scene;
        const bbox = new THREE.Box3().setFromObject(model);
        const center = bbox.getCenter(new THREE.Vector3());

        const newMaterial = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#e662c2"),
          emissive: new THREE.Color("#e662c2"),
          emissiveIntensity: 0.3,
          side: THREE.DoubleSide,
        });
        const newMaterial2 = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#7970d4"),
          emissive: new THREE.Color("#e662c2"),
          emissiveIntensity: 0.3,
          side: THREE.DoubleSide,
        });
        // newMaterial2.polygonOffset = true;
        // newMaterial2.polygonOffsetFactor = -30;

        model.traverse((child) => {
          // grass
          // if (child.material?.name === "Vegetation_Grass1") {
          //   child.material = newMaterial;
          //   child.position.y -= 1;
          // }

          if (child.material?.name === "Color_C10") {
            child.material = newMaterial2;
          }
          // if (child.material?.name === "Water_Pool_Light") {
          //   child.position.y -= 10;
          // }
        });

        model.position.x = -center.x * SCALE_RATIO;
        model.position.z = -center.z * SCALE_RATIO;
        model.scale.set(SCALE_RATIO, SCALE_RATIO, SCALE_RATIO);

        scene.add(model);
        vm.loaderComplete[0] = true;
      });

      const buildingMesh = [];
      let buildingTexts = [];
      let buildingFaces = [];
      gltfLoader.load("./models/線上展基地模_高樓.gltf", (gltf) => {
        const model = gltf.scene;
        const bbox = new THREE.Box3().setFromObject(model);
        const center = bbox.getCenter(new THREE.Vector3());

        const newMaterial1 = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#5f406c"),
        });
        const newMaterial2 = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#e662c2"),
          emissive: new THREE.Color("#e662c2"),
          emissiveIntensity: 0.7,
        });
        const newMaterial3 = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#160a1c"),
        });

        let getObjectPosition = (child) => {
          let vector = new THREE.Vector3(1, 1, 1);
          vector.setFromMatrixPosition(child.matrixWorld);
          // vector.applyMatrix4( child.matrixWorld );
          return vector;
        };
        model.updateMatrixWorld();

        let getAllMeshVerticePosition = (child) => {
          let useObject = child;
          let geometry = useObject.geometry;
          let position = geometry.attributes.position.clone();

          useObject.updateMatrixWorld();
          useObject.updateMatrix();
          useObject.updateWorldMatrix(true, true);
          // //this has side effects to the original array
          let worldPosition = model.localToWorld(position);

          let vA = new THREE.Vector3();
          // let pA = vA.fromBufferAttribute( worldPosition, geometry.index.array[0] );

          let positions = Array.from(geometry.index.array).map((a, aid) => {
            let vA = new THREE.Vector3();
            vA.fromBufferAttribute(worldPosition, a);
            return vA;
          });

          return positions;
        };

        let getMeshPosition = (child) => {
          let useObject = child;
          let geometry = useObject.geometry;
          let position = geometry.attributes.position.clone();

          useObject.updateMatrixWorld();
          useObject.updateMatrix();
          useObject.updateWorldMatrix(true, true);
          // //this has side effects to the original array
          let worldPosition = useObject.localToWorld(position);
          let vA = new THREE.Vector3();
          vA.fromBufferAttribute(worldPosition, geometry.index.array[0]);
          let vB = new THREE.Vector3();
          vB.fromBufferAttribute(
            worldPosition,
            geometry.index.array[geometry.index.array.length - 1]
          );
          let vC = new THREE.Vector3();
          vC.fromBufferAttribute(
            worldPosition,
            geometry.index.array[parseInt(geometry.index.array.length / 2)]
          );

          return vA.add(vB).add(vC).divideScalar(3);
        };

        model.traverse((child) => {
          if (
            (child?.geometry?.index?.count == 36 ||
              child?.geometry?.index?.count == 30) &&
            child.parent.name.indexOf("tower") == -1
          ) {
            // console.log(child?.geometry?.index?.count)
            // console.log(child)
            // console.log(getObjectPosition(child))
            child.isBoard = true;
            child.approxPos = getMeshPosition(child);
            // console.log("board obj",child.approxPos)
            buildingFaces.push(child);
          }
          let name = child?.userData?.name;
          if (name && child?.userData?.name.split("_").length == 3) {
            // console.log(child)
            // console.log(getObjectPosition(child))
            child.updateMatrixWorld();
            child.isText = true;

            child.approxPos = getMeshPosition(child);
            // console.log("text obj",child.approxPos)

            //hide all text
            child.visible = false;
            buildingTexts.push(child);
            // console.log(vector)
            // console.log(child.localToWorld( child.position ))
            // console.log(child?.userData?.name,child.)
          }
        });

        model.traverse((child) => {
          if (child.type === "Group") {
            child.children.forEach(() => {
              if (child.children.isMesh) {
                buildingMesh.push(child.children);
              }
            });
          } else if (child.isMesh) {
            buildingMesh.push(child);
          }

          if (child.material?.name === "greenhouse") {
            child.material = newMaterial1;
          }

          if (child.material?.name === "office01") {
            child.material = newMaterial2;
          }

          if (child.material?.name === "default material") {
            child.material = newMaterial3;
          }
        });
        let forceMapMesh = {
          "830C1651-39CB-412B-9EE8-3F356F92A429": "G_002_2",
          "A7D3F38B-8718-4D8D-88DE-0542466289EC": "G_002_1",
          "F0244883-E340-4633-BA58-A3ADC2D0B7FF": "G_002_0",
          "BE051C1D-A390-4EB0-8214-E8EC8F73F0AE": "G_011_1",
          "A86716C8-47EF-4942-AEF5-5B1D3BE89B42": "G_011_2",
          "89D3614E-A3CC-4394-AC92-2FEC73DC89BF": "G_050_1",
          "9fa96e53-ccb9-442c-a531-78ec08217d44": "G_011_3",
        };

        model.position.x = -center.x * SCALE_RATIO;
        model.position.z = -center.z * SCALE_RATIO;
        model.scale.set(SCALE_RATIO, SCALE_RATIO, SCALE_RATIO);

        buildingFaces.forEach((face) => {
          //get board name and vertices
          let minDist = 400;

          if (forceMapMesh[face.uuid]) {
            face.name = forceMapMesh[face.uuid];
          } else if (face?.userData?.name == "Mesh_264") {
            face.name = "G_011_3";
          } else {
            for (let textId = 0; textId < buildingTexts.length; textId++) {
              let curText = buildingTexts[textId];
              let dist = face.approxPos.distanceTo(curText.approxPos);
              if (dist < 5) {
                face.name = curText.name;
                break;
              }
              if (dist < minDist) {
                // console.log("Face pair",face,curText)
                face.name = curText.name;
                minDist = dist;
              }
            }
          }
          let vertices = getAllMeshVerticePosition(face);

          let folderName = face.name.split("_").slice(0, 2).join("_");

          let imageIndex = face.name.split("_").slice(-1)[0] * 1;

          if (folderName != "G_011") {
            imageIndex += 1;
            if (imageIndex == 5) imageIndex = 1;
          }

          let texturePath = `images/${folderName}/${folderName}_${imageIndex}.jpg`;
          let texturePathPng = `images/${folderName}/${folderName}_${imageIndex}.png`;

          let initTexture = function (texture) {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            let squareWidth = 1;
            if (texture?.source) {
              //control texture ratio
              var repeatX, repeatY;
              if (texture.source.data.height < texture.source.data.width) {
                repeatX =
                  (squareWidth * texture.source.data.height) /
                  (squareWidth * texture.source.data.width);
                repeatY = 1;
                texture.repeat.set(repeatX, repeatY);
                texture.offset.x = ((repeatX - 1) / 2) * -1;
              } else {
                repeatX = 1;
                repeatY =
                  (squareWidth * texture.source.data.width) /
                  (squareWidth * texture.source.data.height);
                texture.repeat.set(repeatX, repeatY);
                texture.offset.y = ((repeatY - 1) / 2) * -1;
              }
            }
          };

          let newMeshFace;
          let workMaterial, newTexture;
          let texture = new THREE.TextureLoader().load(
            texturePath,
            () => {
              initTexture(texture);
            },

            // onProgress callback currently not supported
            undefined,

            // onError callback
            function (err) {
              // console.error("An error happened.");
              newTexture = new THREE.TextureLoader().load(
                texturePathPng,
                () => {
                  initTexture(newTexture);
                },
                undefined,
                function (err) {
                  //really dont have mappnig image then hide the image board
                  newMeshFace.visible = false;
                }
              );
              workMaterial.map = newTexture;
              workMaterial.needsUpdate = true;
            }
          );

          workMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            map: texture,
            side: THREE.DoubleSide,
            // wireframe: false
          });

          let makeFace = (centerPoint, normal, workMaterial) => {
            const geometry = new THREE.PlaneGeometry(1, 1);
            const material = new THREE.MeshBasicMaterial({
              color: 0xffff00,
              side: THREE.DoubleSide,
            });
            const plane = new THREE.Mesh(geometry, workMaterial);
            plane.position.copy(centerPoint);
            plane.lookAt(centerPoint.clone().add(normal));
            geometry.scale(1.25, 1.25, 1.25);
            scene.add(plane);
            return plane;
          };

          // console.log("Face vertices",vertices)

          let useObject = face;
          // map materials
          // console.log("making fake board!");

          let vA = vertices[0];
          let vB = vertices[1];
          let vC = vertices[2];

          const material1 = new THREE.MeshBasicMaterial({ color: 0xff0000 });
          const material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
          const material3 = new THREE.MeshBasicMaterial({ color: 0x0000ff });
          const sphereGeometry = new THREE.SphereGeometry(0.035, 4, 4);

          if (face.geometry?.index?.count == 30) {
            vA = vertices[7];
            vB = vertices[15];
            vC = vertices[1];

            let id = 0;
            // 如果取的第三點跟前兩點位置重複，就繼續往下找不一樣的角點
            while (vC.distanceTo(vB) < 0.5 || vC.distanceTo(vA) < 0.5) {
              vC = vertices[id++];
            }

            // RGB
            // BRG
            // const geometry = new THREE.SphereGeometry(0.02, 16, 16);
            // const material = new THREE.MeshBasicMaterial({ color: 0xff00 });
            // const sphere = new THREE.Mesh(geometry, material);
            // sphere.position.copy(vertices[8]);
            // scene.add(sphere);
            // vA = vertices[10] || vA;
            // vB = vertices[11] || vB;
            // vC = vertices[15] || vC;
          }

          if (face.name == "G_002_0") {
            console.log("its me!!", face);
            console.log(newMeshFace, [vA, vB, vC]);
          }
          if (face.name == "G_038_0") {
            console.log("its me!!", face);
            console.log(newMeshFace, [vA, vB, vC]);
          }
          //for debug
          // [vA, vB, vC].forEach((v, vId) => {
          //   let sphere = new THREE.Mesh(
          //     sphereGeometry,
          //     [material1, material2, material3][vId]
          //   );
          //   sphere.position.copy(v);

          //   scene.add(sphere);
          // });

          // let face = which.face;
          let geometry = useObject.geometry;
          let position = geometry.attributes.position.clone();

          useObject.updateMatrixWorld();
          useObject.updateMatrix();
          useObject.updateWorldMatrix(true, true);

          //this has side effects to the original array
          // let worldPosition = useObject.localToWorld(position);

          // //get corner point world position
          // vA.fromBufferAttribute(worldPosition, face.a);
          // vB.fromBufferAttribute(worldPosition, face.b);
          // vC.fromBufferAttribute(worldPosition, face.c);

          let pointVec3Arr = [];
          pointVec3Arr.push(vA, vB, vC);
          //pan a bit from the origial plane
          let midPoint = [vA, vB, vC].filter((testPoint) => {
            let vectors = [vA, vB, vC]
              .filter((p) => p !== testPoint)
              .map((pp) => pp.clone().sub(testPoint));
            //不嚴格要求一定完全垂直，避免取點中點取不到異常的情況
            return vectors[0].dot(vectors[1]) <= 0.1;
          })[0];
          if (!midPoint) midPoint = vA.clone().add(vB).divideScalar(2);

          let vv1 = vB.clone().sub(vA);
          let vv2 = vC.clone().sub(vA);
          let caculatedNormal = vv1.clone().cross(vv2).normalize();

          //add pan to original plane
          // pointVec3Arr.forEach((p) =>
          //   p.add(caculatedNormal.clone().multiplyScalar(0.03))
          // );

          // console.log("The middle point of face is",midPoint)

          let otherTwoPoints = pointVec3Arr.filter((p) => p !== midPoint);

          let faceCenterPoint = otherTwoPoints[0]
            .clone()
            .add(otherTwoPoints[1])
            .divideScalar(2);
          let fourthPoint = faceCenterPoint
            .clone()
            .sub(midPoint)
            .multiplyScalar(2)
            .add(midPoint);
          pointVec3Arr.push(fourthPoint);

          newMeshFace = makeFace(
            faceCenterPoint,
            caculatedNormal,
            workMaterial
          );

          let material = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            side: THREE.DoubleSide,
          });

          //add plane light
          // let width = 10;
          // let height = 10;
          // let intensity = 1;
          // let rectLight = new THREE.RectAreaLight(
          //   0xffffff,
          //   intensity,
          //   width,
          //   height
          // );
          // rectLight.position.copy(faceCenterPoint);
          // rectLight.lookAt(faceCenterPoint.clone().add(caculatedNormal));
          // scene.add(rectLight);
          // const light = new THREE.PointLight(0xff93ce, 1, 100);
          // light.distance = 0.1;
          // light.position.copy(faceCenterPoint);
          // scene.add(light);

          // vertices.forEach((vert) => {
          //   let geometry = new THREE.SphereGeometry(0.01, 16, 16);
          //   const material = new THREE.MeshBasicMaterial({ color: 0xff00 });
          //   const sphere = new THREE.Mesh(geometry, material);
          //   sphere.position.copy(vert);
          //   scene.add(sphere);
          // });
          face.visible = false;
        });
        let getBoundingCenter = (child) =>
          child?.geometry?.boundingSphere?.center;
        scene.add(model);

        vm.loaderComplete[1] = true;
      });

      gltfLoader.load("./models/線上展基地模_房.gltf", (gltf) => {
        const model = gltf.scene;
        const bbox = new THREE.Box3().setFromObject(model);
        const center = bbox.getCenter(new THREE.Vector3());

        const wireframeMaterial = new THREE.LineBasicMaterial({
          color: new THREE.Color("#93a6e8"),
          linewidth: 5,
        });

        const houseMaterial = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#21092b"),
          opacity: 0.85,
          transparent: true,
          // side: THREE.DoubleSide,
        });

        model.traverse((child) => {
          if (
            child.material?.name === "Material40" ||
            child.material?.name === "Material39" ||
            child.material?.name === "Color_C10" ||
            child.material?.name === "Color_D05"
          ) {
            child.material = houseMaterial;
            const edges = new THREE.EdgesGeometry(child.geometry);
            const wireframe = new THREE.LineSegments(edges, wireframeMaterial);

            // 預防衝突
            wireframe.position.x += 0.01;
            wireframe.position.y += 0.01;
            wireframe.position.z += 0.01;
            child.add(wireframe);
          }
        });

        model.position.x = -center.x * SCALE_RATIO;
        model.position.z = -center.z * SCALE_RATIO;

        model.scale.set(SCALE_RATIO, SCALE_RATIO, SCALE_RATIO);

        scene.add(gltf.scene);

        vm.loaderComplete[2] = true;
      });

      gltfLoader.load("./models/飛船/scene.gltf", (gltf) => {
        const airShipModel = gltf.scene;

        const newMaterial = new THREE.MeshStandardMaterial({
          color: new THREE.Color("#f1f2fb"),
        });

        airShipModel.traverse((child) => {
          if (child.material?.name === "Material.004") {
            child.material = newMaterial;
          }
        });

        airShipModel.scale.set(6, 6, 6);
        airShipModel.position.y = 12;
        airShipModel.position.x = -30;
        airShipModel.position.z = -30;

        scene.add(airShipModel);

        vm.loaderComplete[3] = true;
      });

      gltfLoader.load("./models/起重機/scene.gltf", (gltf) => {
        const model = gltf.scene;

        model.position.x = -7.5;
        model.position.z = 11.5;
        model.rotation.y = 9.7;

        scene.add(model);

        vm.loaderComplete[4] = true;
      });

      /**
       * Lights
       */
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.set(1024, 1024);
      directionalLight.shadow.camera.far = 15;
      directionalLight.shadow.camera.left = -7;
      directionalLight.shadow.camera.top = 7;
      directionalLight.shadow.camera.right = 7;
      directionalLight.shadow.camera.bottom = -7;
      directionalLight.position.set(15, 15, 15);
      scene.add(directionalLight);

      const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      /**
       * Camera
       */
      const camera = new THREE.PerspectiveCamera(
        75,
        sizes.width / sizes.height
      );
      camera.position.set(30, 60, 50);
      camera.lookAt(new THREE.Vector3(0, 0, 0));
      scene.add(camera);

      /**
       * Controls
       */
      const controls = new OrbitControls(camera, canvas);
      controls.maxDistance = 250;

      /**
       * Postprocessing
       */
      const renderScene = new RenderPass(scene, camera);

      // bloom params
      const params = {
        exposure: -0.5,
        bloomStrength: 0.12,
        bloomThreshold: 0.5,
        bloomRadius: 0.22,
        scene: "Scene with Glow",
      };

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1,
        1.1,
        0.18
      );

      bloomPass.enabled = true; // temporary using this to close bloomPass effect
      bloomPass.threshold = params.bloomThreshold;
      bloomPass.strength = params.bloomStrength;
      bloomPass.radius = params.bloomRadius;

      const bloomComposer = new EffectComposer(renderer);
      bloomComposer.renderToScreen = true;
      bloomComposer.addPass(renderScene);
      bloomComposer.addPass(bloomPass);

      /**
       * Raycaster
       */
      const raycaster = new THREE.Raycaster();
      const pointer = new THREE.Vector2();
      let focus = false;

      // 處理點擊建築物，並移動相機
      const handleClick = (event) => {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(pointer, camera);

        const intersect = raycaster.intersectObjects(buildingMesh);

        // console.log(intersect);

        if (intersect.length !== 0) {
          focus = true;
          let minDistance = Infinity;
          let which = null;

          intersect.forEach((el) => {
            if (el.distance <= minDistance) {
              minDistance = el.distance;
              which = el;
            }
          });

          console.log(which);
          // let folderName = which.object.name.split("_").slice(0, 2).join("_");
          // let imageIndex = which.object.name.split("_").slice(-1)[0] * 1 + 1;

          // //照片都沒有第五張
          // if (imageIndex == 5) imageIndex = 1;
          // let texturePath = `images/${folderName}/${folderName}_${imageIndex}.jpg`;
          // console.log(texturePath);
          // let initTexture = function (texture) {
          //   texture.wrapS = THREE.RepeatWrapping;
          //   texture.wrapT = THREE.RepeatWrapping;
          //   if (texture?.source) {
          //     //control texture ratio
          //     var repeatX, repeatY;
          //     if (texture.source.data.height < texture.source.data.width) {
          //       repeatX =
          //         (squareWidth * texture.source.data.height) /
          //         (squareWidth * texture.source.data.width);
          //       repeatY = 1;
          //       texture.repeat.set(repeatX, repeatY);
          //       texture.offset.x = ((repeatX - 1) / 2) * -1;
          //     } else {
          //       repeatX = 1;
          //       repeatY =
          //         (squareWidth * texture.source.data.width) /
          //         (squareWidth * texture.source.data.height);
          //       texture.repeat.set(repeatX, repeatY);
          //       texture.offset.y = ((repeatY - 1) / 2) * -1;
          //     }
          //   }
          // };

          // if (which.object.isBoard) {
          //   let texturePathPng = `images/${folderName}/${folderName}_${imageIndex}.png`;
          //   let texture = new THREE.TextureLoader().load(
          //     texturePath,
          //     initTexture,

          //     // onProgress callback currently not supported
          //     undefined,

          //     // onError callback
          //     function (err) {
          //       // console.error("An error happened.");
          //       texture = new THREE.TextureLoader().load(
          //         texturePathPng,
          //         initTexture
          //       );
          //     }
          //   );
          //   // texture.repeat.set(1, 1);
          //   let workMaterial = new THREE.MeshBasicMaterial({
          //     color: 0xffffff,
          //     map: texture,
          //     side: THREE.DoubleSide,
          //     // wireframe: false
          //   });
          //   console.log("making fake board!");

          //   let vA = new THREE.Vector3();
          //   let vB = new THREE.Vector3();
          //   let vC = new THREE.Vector3();

          //   let face = which.face;
          //   let useObject = which.object;
          //   let geometry = useObject.geometry;
          //   let position = geometry.attributes.position.clone();

          //   useObject.updateMatrixWorld();
          //   useObject.updateMatrix();
          //   useObject.updateWorldMatrix(true, true);

          //   //this has side effects to the original array
          //   let worldPosition = useObject.localToWorld(position);

          //   //get corner point world position
          //   vA.fromBufferAttribute(worldPosition, face.a);
          //   vB.fromBufferAttribute(worldPosition, face.b);
          //   vC.fromBufferAttribute(worldPosition, face.c);

          //   let pointVec3Arr = [];
          //   pointVec3Arr.push(vA, vB, vC);
          //   //pan a bit from the origial plane
          //   let midPoint = [vA, vB, vC].filter((testPoint) => {
          //     let vectors = [vA, vB, vC]
          //       .filter((p) => p !== testPoint)
          //       .map((pp) => pp.clone().sub(testPoint));
          //     return vectors[0].dot(vectors[1]) == 0;
          //   })[0];

          //   let vv1 = vB.clone().sub(vA);
          //   let vv2 = vC.clone().sub(vA);
          //   let caculatedNormal = vv1.clone().cross(vv2).normalize();

          //   pointVec3Arr.forEach((p) =>
          //     p.add(caculatedNormal.clone().multiplyScalar(0.02))
          //   );

          //   // console.log("The middle point of face is",midPoint)

          //   let otherTwoPoints = pointVec3Arr.filter((p) => p !== midPoint);

          //   let faceCenterPoint = otherTwoPoints[0]
          //     .clone()
          //     .add(otherTwoPoints[1])
          //     .divideScalar(2);
          //   // console.log("Center Point",faceCenterPoint)
          //   let fourthPoint = faceCenterPoint
          //     .clone()
          //     .sub(midPoint)
          //     .multiplyScalar(2)
          //     .add(midPoint);
          //   pointVec3Arr.push(fourthPoint);

          //   let newMeshFace = makeFace(faceCenterPoint, caculatedNormal);
          //   // console.log(newMeshFace )
          //   let material = new THREE.MeshBasicMaterial({
          //     color: 0xff0000,
          //     side: THREE.DoubleSide,
          //   });
          // }

          if (this.lastClickedName == which.object.name) {
            vm.$emit("show-details", which.object.name);
          }
          this.lastClickedName = which.object.name;
          // which.object.material = workMaterial;
          const DIFF_X = camera.position.x > which.point.x ? 3 : -3;
          const DIFF_Z = camera.position.z > which.point.z ? 3 : -3;

          gsap.to(camera.position, {
            duration: 1,
            x: which.point.x + DIFF_X,
            y: which.point.y + 3,
            z: which.point.z + DIFF_Z,
            onUpdate: () => {
              camera.lookAt(which.point);
            },
          });

          gsap.to(controls.target, {
            duration: 1,
            x: which.point.x,
            y: which.point.y,
            z: which.point.z,
            onComplete: () => {
              controls.target.set(which.point.x, which.point.y, which.point.z);
            },
          });
        }
      };

      window.addEventListener("click", handleClick);

      // 點擊Esc
      const handleKeyDown = (event) => {
        if (focus && event.keyCode === 27) {
          gsap.to(camera.position, {
            duration: 1,
            x: 30,
            y: 60,
            z: 50,
            onComplete: () => {
              camera.lookAt(new THREE.Vector3(0, 0, 0));
            },
          });

          gsap.to(controls.target, {
            duration: 1,
            x: 0,
            y: 0,
            z: 0,
            onComplete: () => {
              controls.reset();
            },
          });
          focus = false;
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      window.addEventListener("resize", handleResize);

      //fog
      scene.add(fogMesh);
      scene.add(auroraMesh);
      fogMesh.scale.x = 5;
      fogMesh.scale.y = 5;
      fogMesh.scale.z = 5;
      auroraMesh.scale.x = 20;
      auroraMesh.scale.y = 40;
      auroraMesh.scale.z = 20;
      // console.log("fog", fogMesh);

      function handleResize() {
        // Update sizes
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        // Update camera
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();

        // Update renderer
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        bloomComposer.setSize(sizes.width, sizes.height);
        bloomComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      }

      handleResize();

      const clock = new THREE.Clock();

      function tick() {
        const elapsedTime = clock.getElapsedTime();

        // Update controls
        controls.update();

        // Render
        fogAnimationUpdate && fogAnimationUpdate(camera);
        renderer.render(scene, camera);
        bloomComposer.render();

        // Call tick again on the next frame
        window.requestAnimationFrame(tick);
      }

      tick();
    },
  },
};
</script>

<style lang="scss" scoped>
.webgl {
  width: 100%;
  height: 100%;
}
</style>
