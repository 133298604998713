<template>
  <div class="3d-tour-page">
    <!-- <ComingSoon /> -->
    <Canvas @show-details="openModal"></Canvas>
    <ThreeDModal
      v-if="currentData && modalOpen"
      @close="modalOpen = false"
      :view-image="viewImage"
      :data="currentData"
    />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ComingSoon from "@/components/organisms/ComingSoonV2";
import ThreeDModal from "@/components/organisms/ThreeDModal";
import Canvas from "./canvas.vue";
import mapping from "./mapping";

function importModules(r) {
  return r.keys().reduce((acc, key) => {
    acc.push(key);
    return acc;
  }, []);
}

const imageMap = importModules(
  require.context("../../../public/images", true, /\_original\.(jpg|png)$/)
);

export default {
  name: "3DTour",
  components: { ComingSoon, ThreeDModal, Canvas },
  setup() {
    const store = useStore();
    const modalOpen = ref(false);
    const currentId = ref(null);
    const viewImage = ref(null);
    const currentData = computed(() =>
      store.getters.portfolios.find((o) => o.work_id === currentId.value)
    );

    function openModal(mappingId) {
      // validate ID
      if (!/^[A-Z]\_[0-9]{3}/.test(mappingId)) return;
      const id = mapping.get(mappingId.slice(0, -2));
      let imageId = Number(mappingId.slice(-1)) + 1;

      if (imageId >= 5) imageId = 1;
      const theIndex = imageMap.findIndex((o) =>
        o.includes(`${mappingId.slice(0, -1)}${imageId}`)
      );
      const theImage = imageMap[theIndex];
      viewImage.value = theImage ? `/images${theImage.slice(1)}` : null;
      currentId.value = id;
      modalOpen.value = true;
    }

    return {
      viewImage,
      currentData,
      currentId,
      modalOpen,
      openModal,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
