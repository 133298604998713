export default new Map([
  ['N_002', 744],
  ['D_003', 711],
  ['C_004', 708],
  ['A_005', 701],
  ['A_006', 702],
  ['L_007', 737],
  ['E_008', 714],
  ['N_009', 746],
  ['G_011', 721],
  ['K_014', 734],
  ['L_015', 736],
  ['C_017', 709],
  ['B_020', 706],
  ['E_021', 715],
  ['N_025', 743],
  ['E_026', 713],
  ['H_035', 725],
  ['E_036', 716],
  ['J_037', 732],
  ['G_038', 722],
  ['H_039', 726],
  ['O_040', 749],
  ['H_041', 727],
  ['O_042', 748],
  ['O_044', 747],
  ['B_045', 707],
  ['I_046', 730],
  ['J_047', 733],
  ['D_049', 712],
  ['G_050', 723],
  ['M_053', 742],
  ['H_054', 724],
  ['J_056', 731],
  ['O_060', 750],
  ['F_061', 719],
  ['N_063', 745],
  ['D_071', 710],
  ['I_072', 729],
  ['A_074', 703],
  ['K_075', 735],
  ['F_057', 718],
  ['I_013', 728],
  ['B_514', 705],
  ['M_048', 740],
  ['L_065', 738],
  ['B_010', 704],
  ['M_034', 739],
  ['M_051', 741],
  ['G_002', 720],
  ['F_003', 717],
  ['B_210', 705]
])