<template>
  <div class="coming-soon-page">
    <span>3D Tour Coming Soon</span>
  </div>
</template>

<script>
import YouTube from "vue3-youtube";
import { ref } from "vue";
export default {
  name: "ComingSoonV2",
  components: { YouTube },
  setup() {
    const youtube = ref(null);
    return {
      youtube,
    };
  },
};
</script>

<style lang="scss" scoped>
.coming-soon-page {
  position: absolute;
  background-color: $black;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  user-select: none;

  background-image: url(../../../images/backgrounds/comingsoonImage.png);
  background-size: cover;
}

span {
  font-family: 'Zpix';
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: $white;
  margin-top: 80px;
}
</style>
